import { useTranslation } from "react-i18next";
import {
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { showDialog } from "../../../../../redux/dialogs/actions";
import {
  fetchAccountActivationByOrganizationId,
  removeAccountActivationByOrganizationId,
} from "../../../../../redux/accountInvitation/actions";

/**
 * Component for rendering a list of organization accountInvitations.
 * @param {Object} props - Component props.
 * @param {Array} props.accountInvitations - List of organization accountInvitations.
 * @param {string} props.organizationId - ID of the organization.
 * @returns {JSX.Element} - Rendered component.
 */
const AccountInvitationList = ({ accountInvitations, organizationId }) => {
  const { t } = useTranslation(["common", "organization"]);
  const dispatch = useDispatch();

  /**
   * Handler function for removing an accountInvitation.
   * @param {Object} accountInvitation - The accountInvitation to be removed.
   * @param {string} title - Title for the dialog box.
   */
  const removeMembershipHandler = async (accountInvitation, title) => {
    dispatch(
      showDialog({
        title: title,
        message: t("accountInvitation.revokeAccountInvitationConfirmationText", {
          firstname: accountInvitation?.firstname,
          lastname: accountInvitation?.lastname,
          ns: "organization",
        }),
        confirmButtonText: t("accountInvitation.revokeAccountInvitationConfirmation", {
          ns: "organization",
        }),
        action: async () => {
          await dispatch(removeAccountActivationByOrganizationId(accountInvitation?.id));
          await dispatch(fetchAccountActivationByOrganizationId(organizationId));
        },
      })
    );
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t("profile.firstName", { ns: "common" })}</TableCell>
            <TableCell>{t("profile.lastName", { ns: "common" })}</TableCell>
            <TableCell>{t("profile.mail", { ns: "common" })}</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {accountInvitations?.map((accountInvitation) => (
            <TableRow key={accountInvitation.id}>
              <TableCell>{accountInvitation.first_name}</TableCell>
              <TableCell>{accountInvitation.last_name}</TableCell>
              <TableCell>{accountInvitation.email}</TableCell>
              <TableCell>
                <Button
                  id="revoke-account-invitation"
                  size="large"
                  variant="outlined"
                  color="secondary"
                  style={{ width: "100%" }}
                  onClick={() =>
                    removeMembershipHandler(
                      accountInvitation,
                      t("accountInvitation.revokeAccountInvitation", {
                        ns: "organization",
                      })
                    )
                  }
                >
                  {t("accountInvitation.revokeAccountInvitation", {
                    ns: "organization",
                  })}
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AccountInvitationList;
