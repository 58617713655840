import { Box } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { useSelector } from "react-redux";
import { LumosUserSvg } from "../../../../assets/icons/custom-svg-components/LumosSvgCollection";
import { mapToUserTypeString } from "../../../../models/enums/userType.enum";
import { AppState } from "../../../../redux";
import "./Profile.scss";
import usePermission from "../../../../services/usePermissions";
import { FavoriteStar } from "./FavoriteStar";
import { useTranslation } from "react-i18next";
import { Cart } from "./Cart";

export const Profile: React.FC = () => {
  const { t } = useTranslation(["navigationData", "snackbars"]);
  const userData = useSelector((state: AppState) => state.user.currentUser);
  const { isParticipant } = usePermission(userData?.user_type);

  return (
    <Box display="flex" alignItems="center">
      {isParticipant() && <FavoriteStar />}
      {isParticipant() && <Cart />}
      <Box mx={2} my={1}>
        <Typography>
          {userData?.user_type !== 20 ? (
            <>
              <Box component="span" className="header-profile-role-text">
                {t(mapToUserTypeString(userData?.user_type), {
                  ns: "navigationData",
                })}
              </Box>
              <br />
              <Box component="span" className="header-profile-role-name">
                {userData?.fullname}
              </Box>
            </>
          ) : (
            <Box component="span" className="header-profile-role-name">
              {userData?.fullname}
            </Box>
          )}
        </Typography>
      </Box>
      <Box mb={1} className="header-profile-role-picture">
        <LumosUserSvg />
      </Box>
    </Box>
  );
};
