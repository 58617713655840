import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../../redux";
import { Box, Grid } from "@material-ui/core";
import { removeImageCapture } from "../../../../../redux/videoAuthentication/actions";
import { ImageWithDeleteIcon } from "../misc/ImageWithDeleteIcon";
import { useTranslation } from "react-i18next";

/**
 * Renders a list of image captures.
 *
 * @component
 * @param {Object} props - Component props
 * @param {boolean} props.isEditable - Determines if the capture list is editable.
 * @returns {JSX.Element} - Rendered component.
 */
export const CaptureList = ({
  isEditable,
  lastCaptureRef = null,
}: {
  isEditable: boolean;
  lastCaptureRef?: any;
}) => {
  const { t } = useTranslation(["authCapture", "snackbars"]);
  const { captures } = useSelector((state: AppState) => state.videoAuthentication);
  const dispatch = useDispatch();

  /**
   * Handles the removal of a capture.
   *
   * @param {string} capture - The capture to be removed.
   */
  function handleRemoveCapture(capture: string) {
    dispatch(removeImageCapture(capture));
  }

  return (
    <Grid container spacing={2}>
      {captures.length > 1 && (
        <Grid item xs={12}>
          <Box>{t("shots.shotSelect", { ns: "authCapture" })}</Box>
        </Grid>
      )}
      {captures.map((capture, index) => {
        const isLastCapture = index === captures.length - 1;

        return (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            style={{ display: "flex", justifyContent: "center" }}
            key={capture}
            ref={isLastCapture ? lastCaptureRef : null}
          >
            <ImageWithDeleteIcon
              src={capture}
              alt={`${t("shots.shot", { ns: "authCapture" })} ${index}`}
              title={t("shots.title", { ns: "authCapture" })}
              hideDeleteButton={!isEditable}
              onDelete={() => handleRemoveCapture(capture)}
              isMemberShipDocumentPage={false}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};
