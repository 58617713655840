import React, { useEffect, useState } from "react";
import { Box, Divider, Grid, Link } from "@material-ui/core";
import DescriptionIcon from "@material-ui/icons/Description";
import GetAppIcon from "@material-ui/icons/GetApp";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../../redux";
import "../../../../../pages/core/event/UserEventsPage.scss";
import { DocumentsState } from "../../../../../redux/documents/types";
import Loader from "../../../../theming/loader/Loader";
import ConfirmationDialog from "../../../../layout/dialog/ConfirmationDialog";
import { ParticipationStatus } from "../../../../../models/enums/participationStatus.enum";
import RevokedCertificateDialog from "../../../../layout/dialog/RevokedCertificateDialog";
import { useTranslation } from "react-i18next";
import { isExpired } from "../../shared/checkEventIsOver";
import CertificateAccessDeniedDialogue from "../../../../layout/dialog/CertificateAccessDeniedDialogue";
import { metaDataService } from "../../../../../api";
import i18n from "i18next";

interface EventDocumentsProps {
  documents: DocumentsState;
  no_automatic_participation_certificate: boolean;
}

const EventDocuments: React.FC<EventDocumentsProps> = ({
  documents,
  // eslint-disable-next-line camelcase
  no_automatic_participation_certificate,
}) => {
  const { t } = useTranslation(["customer", "events", "snackbars"]);
  const accessibility = useSelector((state: AppState) => state.accessibility);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [policyConfirmed, setPolicyConfirmed] = useState<boolean>(false);
  const [certificateLink, setCertificateLink] = useState<string>();
  const [certificateDownloadDe, setCertificateDownloadDe] = useState("");
  const [certificateDownloadEn, setCertificateDownloadEn] = useState("");
  const [metaDataIsLoading, setMetaDataIsLoading] = useState(true);
  const dispatch = useDispatch();

  const bookings = useSelector((state: AppState) => state.booking);
  const handleConfirm = () => {
    setPolicyConfirmed(true);
    setDialogOpen(false);
    // eslint-disable-next-line no-unused-expressions
    certificateLink && window.open(certificateLink, "_blank");
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleDownloadCertificate = () => {
    if (!policyConfirmed) setDialogOpen(true);
    // eslint-disable-next-line no-unused-expressions
    else certificateLink && window.open(certificateLink, "_blank");
  };

  /**
   * Fetch metadata from the API and set the certificate download content.
   */
  useEffect(() => {
    metaDataService
      .getMetaData()
      .then((metaData) => {
        setCertificateDownloadDe(metaData?.data?.confirmation_certificate_german);
        setCertificateDownloadEn(metaData?.data?.confirmation_certificate_english);
        setMetaDataIsLoading(false);
      })
      .catch(() => {
        setMetaDataIsLoading(false);
      });
  }, []);

  const certificateDownloadText =
    i18n.language === "en" ? certificateDownloadEn : certificateDownloadDe;

  return (
    <>
      <Grid item xs={12}>
        <Divider />
        <Box component="div" className="myevents-details-headline">
          {t("materials.title", { ns: "events" })}
        </Box>
      </Grid>
      <Grid item xs={12}>
        {documents.isLoading ? (
          <Loader />
        ) : (
          documents.documentList.map((document, index) => {
            if (
              document?.title === "Teilnahmebescheinigung" &&
              // eslint-disable-next-line camelcase
              no_automatic_participation_certificate
            ) {
              return null;
            } else
              return (
                <Box
                  display="flex"
                  className="myevents-details-document-list-item"
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  px={1}
                >
                  <Box px={1}>
                    <DescriptionIcon />
                  </Box>
                  <Box
                    flexGrow={1}
                    px={1}
                    style={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {document.title}
                  </Box>
                  <Box px={1}>
                    {document.title === "Teilnahmebescheinigung" ? (
                      <GetAppIcon
                        className="myevents-details-document-download-icon"
                        // onClick={() => {
                        //   setCertificateLink(document.file);
                        //   handleDownloadCertificate();
                        // }}
                        onClick={() => {
                          if (
                            bookings.myEvent.status ===
                            ParticipationStatus.CertificateRevoked
                          ) {
                            dispatch(RevokedCertificateDialog());
                          } else if (
                            isExpired(
                              bookings.myEvent
                                .participation_certificate_availability_date
                            )
                          ) {
                            dispatch(CertificateAccessDeniedDialogue());
                          } else {
                            setCertificateLink(document.file);
                            handleDownloadCertificate();
                          }
                        }}
                      />
                    ) : (
                      <Link
                        href={document.file}
                        download={document.file}
                        target="_blank"
                        className="myevents-details-document-download-icon"
                      >
                        <GetAppIcon
                          color={accessibility.monoMode ? "secondary" : undefined}
                        />
                      </Link>
                    )}
                  </Box>
                  {metaDataIsLoading ? (
                    <Loader />
                  ) : (
                    <ConfirmationDialog
                      content={certificateDownloadText}
                      open={dialogOpen}
                      onClose={handleCloseDialog}
                      onConfirm={handleConfirm}
                    />
                  )}
                </Box>
              );
          })
        )}
      </Grid>
    </>
  );
};

export default EventDocuments;
