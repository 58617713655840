/* eslint-disable react-hooks/exhaustive-deps, react/jsx-max-depth */
import { useEffect, useState, Dispatch, SetStateAction } from "react";
import { Box, Grid } from "@material-ui/core";
import CustomSelect from "../../../forms/selects/CustomSelect";
import CustomDatepicker from "../../../forms/CustomDatepicker";
import CustomButton from "../../../theming/CustomButton";
import { Field, Form, Formik, FormikConfig } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { submitGroupMembership } from "../../../../redux/group-membership-documents/actions";
import {
  getCurrentLoggedInUser,
  getCurrentUserData,
} from "../../../../redux/users/actions";
import { MembershipDocumentType } from "../../../../models/groupMembership";
import { useSnackbar } from "notistack";
import { AppState } from "../../../../redux";
import { AUTH_DOC_REQUIRED } from "../../../../libs/validation_strings";
import Loader from "../../../theming/loader/Loader";
import { useTranslation } from "react-i18next";
import { getGroupMembershipDocumentTypes } from "../../../../redux/groupMembershipDocumentType/actions";
import { SCHOOL_STUDENT_CARD_LABEL } from "../../../../constants";
import { getCurrentLanguage } from "../../localization/getStringTranslation";
import CustomCheckbox from "../../../forms/CustomCheckbox";
import { useDynamicYupValidations } from "../../../../libs/yup-validations";
import { MembershipDocumentRequestDto } from "../../../../models/groupMembership";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { FileObject } from "material-ui-dropzone";

interface MembershipFormData {
  groupMembershipDocumentType: MembershipDocumentType | string;
  valid_until: string;
  document_has_no_expiry_date: boolean;
}

interface MembershipDocumentSelectionProps {
  documentTypeIsSelected: boolean;
  setDocumentTypeIsSelected: Dispatch<SetStateAction<boolean>>;
  isRegistrationPage: boolean;
  groupMembershipDocument: MembershipDocumentRequestDto;
  setGroupMembershipDocument: Dispatch<SetStateAction<MembershipDocumentRequestDto>>;
  files: FileObject[];
  setFiles: Dispatch<SetStateAction<FileObject[]>>;
}

const currentDateLocal = new Date();

export const MembershipDocumentSelection: React.FC<MembershipDocumentSelectionProps> = (
  props
) => {
  const {
    documentTypeIsSelected,
    setDocumentTypeIsSelected,
    isRegistrationPage,
    groupMembershipDocument,
    setGroupMembershipDocument,
    files,
    setFiles,
  } = props;

  let currentUser: any = undefined;
  if (!isRegistrationPage) {
    currentUser = useSelector((state: AppState) => state.user.currentUser);
  }

  // eslint-disable-next-line
  const [user, setUser] = useState(currentUser);

  const [userIsLoading, setUserIsLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false); // img upload
  const today = currentDateLocal.toDateString();
  const maxAge = process.env.REACT_APP_MAX_AGE_SCHOOL_STUDENT_CARD;
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation([
    "common",
    "participationTypes",
    "snackbars",
    "validations",
  ]);

  const [submittable, setSubmittable] = useState(false);

  const initialGroupMembershipDocumentValues: MembershipDocumentRequestDto = {
    file: "",
    valid_until: today,
    document_has_no_expiry_date: false,
    document_type: groupMembershipDocument.document_type,
  };

  const groupMembershipDocumentTypes = useSelector(
    (state: AppState) => state.groupMemberShipDocumentTypes
  );

  let groupMembershipDocumentTypeOptions =
    groupMembershipDocumentTypes.groupMembershipDocumentTypes.map((documentType) => {
      const localeLabel = `label_${getCurrentLanguage()}` as keyof typeof documentType;
      return {
        key: documentType.id,
        value: documentType.id,
        label: "" + documentType[localeLabel] || documentType.label,
      };
    });

  const {
    YupValidationGroupDocumentDateSelected,
    YupValidationGroupMembershipDocumentType,
  } = useDynamicYupValidations();

  const [groupMembershipDocumentTypeOption, setGroupMembershipDocumentTypeOption] =
    useState<MembershipDocumentType | "">("");

  const dateDisabled =
    documentTypeIsSelected === false ||
    groupMembershipDocument.document_has_no_expiry_date === true;

  const formikConf: FormikConfig<MembershipFormData> = {
    initialValues: {
      groupMembershipDocumentType: groupMembershipDocument.document_type,
      valid_until: today, // eslint-disable-line
      document_has_no_expiry_date: groupMembershipDocument.document_has_no_expiry_date, // eslint-disable-line
    },

    validationSchema: yup.object({
      groupMembershipDocumentType:
        isRegistrationPage || !submittable
          ? YupValidationGroupMembershipDocumentType(
              groupMembershipDocumentTypeOptions
            ).nullable()
          : YupValidationGroupMembershipDocumentType(
              groupMembershipDocumentTypeOptions
            ).required(t(AUTH_DOC_REQUIRED, { ns: "validations" })),
      valid_until: YupValidationGroupDocumentDateSelected(
        "valid_until",
        "document_has_no_expiry_date"
      ),
    }),
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    onSubmit(values) {
      submitGroupMembershipDocument();
    },
    validateOnChange: true,
  };

  const [age, setAge] = useState<number | undefined>(undefined);
  /* TODO Refactor to avoid workaround and multiple rerendering */

  useEffect(() => {
    if (!isRegistrationPage && !currentUser) {
      getCurrentUserData(dispatch);
    }
  }, [userIsLoading]);

  useEffect(() => {
    if (!isRegistrationPage && userIsLoading) {
      getCurrentUserData(dispatch);
      setUser(currentUser);
      setUserIsLoading(false);
    }
  }, [currentUser]);

  useEffect(() => {
    if (
      !groupMembershipDocumentTypes.isLoading &&
      !groupMembershipDocumentTypes.groupMembershipDocumentTypesLoaded
    ) {
      dispatch(getGroupMembershipDocumentTypes());
    }
  }, [groupMembershipDocumentTypes]);

  useEffect(() => {
    if (files.length === 0 || !documentTypeIsSelected) {
      setSubmittable(false);
    }
  }, [submittable, files, documentTypeIsSelected]);

  useEffect(() => {
    if (!isRegistrationPage && currentUser?.birthday) {
      const utcToday = new Date(
        currentDateLocal.getTime() + currentDateLocal.getTimezoneOffset() * 60000
      );
      const birthdayParts = currentUser.birthday.split(".");
      const birthDate = new Date(
        parseInt(birthdayParts[2]),
        parseInt(birthdayParts[1]) - 1,
        parseInt(birthdayParts[0])
      );
      const calcAge =
        utcToday.getFullYear() -
        birthDate.getFullYear() -
        (utcToday.getMonth() < birthDate.getMonth() ||
        (utcToday.getMonth() === birthDate.getMonth() &&
          utcToday.getDate() < birthDate.getDate())
          ? 1
          : 0);

      setAge(calcAge);
    }
  }, [currentUser]);

  // Remove school student card when person is older than maxAge (18)
  if (!age || !maxAge || age >= parseInt(maxAge)) {
    const newArray = groupMembershipDocumentTypeOptions.filter(
      (item) => item.label !== SCHOOL_STUDENT_CARD_LABEL
    );
    groupMembershipDocumentTypeOptions = newArray;
  }

  // eslint-disable-next-line
  const handleChangeCheckbox = (event: any) => {
    const currentValue = groupMembershipDocument.document_has_no_expiry_date;
    setGroupMembershipDocument((prevState) => ({
      ...prevState,
      document_has_no_expiry_date: !currentValue,
    }));
  };

  // eslint-disable-next-line
  const handleChangeDocumentType = (event: any) => {
    setGroupMembershipDocumentTypeOption(event.target.value);
    if (event.target.value && event.target.value.length !== 0) {
      setDocumentTypeIsSelected(true);
    } else {
      setDocumentTypeIsSelected(false);
      setGroupMembershipDocument((prevState) => ({
        ...prevState,
        document_has_no_expiry_date: false,
      }));
    }
    setGroupMembershipDocument((prevState) => ({
      ...prevState,
      document_type: event.target.value,
    }));
  };

  function submitGroupMembershipDocument() {
    const file = files[0].file;
    if (!file) {
      enqueueSnackbar(t("profile.membershipDocError", { ns: "snackbars" }), {
        variant: "error",
      });
      return;
    }

    if (groupMembershipDocument.document_type === "") {
      enqueueSnackbar(t("profile.membershipTypeMissing", { ns: "snackbars" }), {
        variant: "error",
      });
      return;
    }

    let valid_until_date = null;
    if (!groupMembershipDocument.document_has_no_expiry_date) {
      const validUntilMoment = moment(groupMembershipDocument.valid_until);
      valid_until_date = validUntilMoment.format("YYYY-MM-DD");
    }
    setLoading(true);

    let fileData: File | string = "";
    if (file instanceof File) {
      fileData = file;
    }

    dispatch(
      submitGroupMembership(
        fileData,
        groupMembershipDocumentTypeOption,
        valid_until_date,
        groupMembershipDocument.document_has_no_expiry_date,
        setLoading
      )
    );

    setGroupMembershipDocument(initialGroupMembershipDocumentValues);
    setFiles([]);

    if (!isRegistrationPage) {
      getCurrentUserData(dispatch);
    }

    setUserIsLoading(false);
    setUser(undefined);
    if (!isRegistrationPage) {
      dispatch(getCurrentLoggedInUser());
    }
  }

  return (
    <Formik
      key={getCurrentLanguage()}
      {...formikConf} // eslint-disable-line react/jsx-props-no-spreading
      enableReinitialize
    >
      {({ isValid, touched, values, setFieldValue }) => {
        const canSubmit =
          isValid && touched && files.length !== 0 && documentTypeIsSelected;
        {
          if (canSubmit) {
            setSubmittable(true);
          }
        }
        {
          // eslint-disable-line no-empty
        }

        return (
          <Form>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <CustomSelect
                  labelId="groupMembershipDocumentType_label"
                  label={t("docInfo.docType", { ns: "participationTypes" })}
                  name="groupMembershipDocumentType"
                  id="groupMembershipDocumentType"
                  options={groupMembershipDocumentTypeOptions}
                  fullWidth
                  onChange={handleChangeDocumentType}
                  required={files.length !== 0}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  as={CustomDatepicker}
                  id="custom-datepicker-valid-until"
                  name="valid_until"
                  label={t("docInfo.validUntil", {
                    ns: "participationTypes",
                  })}
                  minDateMessage={t("docInfo.dateInvalid", {
                    ns: "participationTypes",
                  })}
                  value={values.valid_until}
                  fullWidth
                  customChangeHandler={(date: MaterialUiPickersDate) => {
                    setFieldValue("valid_until", date);
                    const dateString = date ? date.toLocaleString() : "";
                    setGroupMembershipDocument((prevState) => ({
                      ...prevState,
                      valid_until: dateString,
                    }));
                  }}
                  disabled={dateDisabled}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomCheckbox
                  style={{
                    margin: "1.875rem",
                  }}
                  id="checkbox-no-expiry-date"
                  name="document_has_no_expiry_date"
                  checked={groupMembershipDocument.document_has_no_expiry_date}
                  onChange={handleChangeCheckbox}
                  disabled={!documentTypeIsSelected}
                  label={
                    <span>
                      {t("docInfo.noExpiryDate", {
                        ns: "participationTypes",
                      })}
                    </span>
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Box style={{ marginRight: 50 }}>
                    {Boolean(loading) && (
                      <Loader
                        loadingText={t("waitNote", { ns: "common" })}
                        height={40}
                      />
                    )}
                  </Box>
                  {!isRegistrationPage && (
                    <CustomButton type="submit" disabled={!canSubmit} color="primary">
                      {t("uploadDoc", { ns: "common" })}
                    </CustomButton>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};
