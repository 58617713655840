import { useTranslation } from "react-i18next";
import {
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
} from "@material-ui/core";
import {
  fetchMembershipsByOrganizationId,
  removeMembership,
} from "../../../../../redux/organizationMembership/action";
import { useDispatch } from "react-redux";
import { showDialog } from "../../../../../redux/dialogs/actions";

/**
 * Component for rendering a list of organization memberships.
 * @param {Object} props - Component props.
 * @param {Array} props.memberships - List of organization memberships.
 * @param {string} props.organizationId - ID of the organization.
 * @returns {JSX.Element} - Rendered component.
 */
const MembershipsList = ({ memberships, organizationId }) => {
  const { t } = useTranslation(["common", "organization"]);
  const dispatch = useDispatch();

  /**
   * Handler function for removing a membership or invitation.
   * @param {Object} membership - The membership to be removed.
   * @param {string} title - Title for the dialog box.
   * @param {string} confirmationText - Confirmation text for the dialog.
   * @param {string} confirmButtonText - Text for the confirmation button.
   */
  const removeMembershipHandler = async (membership, title, confirmationText, confirmButtonText) => {
    dispatch(
      showDialog({
        title: title,
        message: confirmationText,
        confirmButtonText: confirmButtonText,
        action: async () => {
          await dispatch(removeMembership(membership?.id));
          await dispatch(fetchMembershipsByOrganizationId(organizationId));
        },
      })
    );
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ fontWeight: "bold" }}>
              {t("profile.firstName", { ns: "common" })}
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }}>
              {t("profile.lastName", { ns: "common" })}
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }}>
              {t("profile.mail", { ns: "common" })}
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }}>
              {t("membership.status", { ns: "organization" })}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {memberships?.map((membership) => {
            return (
              <TableRow key={membership.id}>
                <TableCell>{membership.firstname}</TableCell>
                <TableCell>{membership.lastname}</TableCell>
                <TableCell>{membership.email}</TableCell>
                <TableCell>
                  {membership.status === "pending"
                    ? t("membership.invited", { ns: "organization" })
                    : t("membership.member", { ns: "organization" })}
                </TableCell>
                <TableCell>
                  <Button
                    size="large"
                    variant="outlined"
                    color="secondary"
                    style={{ width: "100%" }}
                    onClick={() =>
                      removeMembershipHandler(
                        membership,
                        membership.status === "pending"
                          ? t("membership.revokeMembershipInvitation", { ns: "organization" })
                          : t("membership.revokeMembership", { ns: "organization" }),
                        membership.status === "pending"
                          ? t("membership.revokeMembershipInvitationConfirmationText", {
                              firstname: membership?.firstname,
                              lastname: membership?.lastname,
                              ns: "organization",
                            })
                          : t("membership.revokeMembershipConfirmationText", {
                              firstname: membership?.firstname,
                              lastname: membership?.lastname,
                              ns: "organization",
                            }),
                        membership.status === "pending"
                          ? t("membership.revokeMembershipInvitationConfirmationButton", {
                              ns: "organization",
                            })
                          : t("membership.revokeMembership", { ns: "organization" })
                      )
                    }
                  >
                    {membership.status === "pending"
                      ? t("membership.revokeMembershipInvitation", { ns: "organization" })
                      : t("membership.revokeMembership", { ns: "organization" })}
                  </Button>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MembershipsList;
