import React, { Dispatch, SetStateAction } from "react";
import { AddMembershipDocumentForm } from "../profile-settings/membership-documents/AddMembershipDocumentForm";
import { MembershipDocumentRequestDto } from "../../../models/groupMembership";
import { FileObject } from "material-ui-dropzone";

interface GroupMembershipDocumentSectionProps {
  groupMembershipDocument: MembershipDocumentRequestDto;
  setGroupMembershipDocument: Dispatch<SetStateAction<MembershipDocumentRequestDto>>;
  files: FileObject[];
  setFiles: Dispatch<SetStateAction<FileObject[]>>;
}

export const GroupMembershipDocumentSection: React.FC<
  GroupMembershipDocumentSectionProps
> = (props) => {
  const { groupMembershipDocument, setGroupMembershipDocument, files, setFiles } = props;

  return (
    <AddMembershipDocumentForm
      isRegistrationPage
      setGroupMembershipDocument={setGroupMembershipDocument}
      groupMembershipDocument={groupMembershipDocument}
      files={files}
      setFiles={setFiles}
    />
  );
};
