/* eslint-disable */
// TODO: Remove eslint disable after refactoring
import { useEffect, useRef, useState } from "react";
import { Grid, Typography, useMediaQuery, Button, Box } from "@material-ui/core";
import { Camera, PlayArrow } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { DocumentType } from "../../../../../models/documentData";
import { AppState } from "../../../../../redux";
import {
  addDocumentCapture,
  addImageCapture as addParticipantCapture,
  setDocumentCaptureRect,
  setParticipantCaptureRect,
} from "../../../../../redux/videoAuthentication/actions";
import example1Mobile from "../../../../../assets/video_authentisierung/authentifizierung-1-mobile.png";
import example2Mobile from "../../../../../assets/video_authentisierung/authentifizierung-2-mobile.png";
import example1 from "../../../../../assets/video_authentisierung/authentifizierung-1.png";
import example2 from "../../../../../assets/video_authentisierung/authentifizierung-2.png";
import {
  residencePermitPhotoDimensions,
  passPhotoDimensions,
  cardIdPhotoDimensions,
  cardIdDimensions,
} from "../../../../../utils/captureDimentions";
import { CaptureList } from "./CaptureList";
import { CaptureIdCardList } from "./CaptureIdCardList";
import { CountDown } from "./CountDown";
import { NavControls } from "./NavControls";
import CaptureHints from "./CaptureHints";
import OnSiteAuthPopup from "./on-site-popup/OnSiteAuthPopup";
import { loggerService } from "../../../../../api";
import { LogLevelType } from "../../../../../models/enums/logLevelType.enum";
import { isMobileDevice } from "../../../../../utils/isMobileDevice";

/**
 * Renders the Capture component.
 * This component handles capturing images from the camera and provides UI for the capture process.
 * @returns JSX element
 */
export const Capture = () => {
  const { t } = useTranslation(["authCapture", "snackbars"]);
  const isMobile = isMobileDevice();
  const isXsMobile = useMediaQuery("(max-width: 400px)");
  // Refs for canvas and video elements
  const lastCaptureRef = useRef<HTMLDivElement | null>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const videoRef = useRef<HTMLVideoElement>(document.createElement("video"));

  // Redux state and dispatch
  const { captures } = useSelector((state: AppState) => state.videoAuthentication);
  const { documentCaptures } = useSelector(
    (state: AppState) => state.videoAuthentication
  );
  const { metaData } = useSelector((state: AppState) => state.videoAuthentication);
  const dispatch = useDispatch();

  // Local state
  const [cameraStream, setCameraStream] = useState<MediaStream | null>(null);
  const [timerStarted, setTimerStarted] = useState<boolean>(false);
  const [modeCardIDCapture, setModeCardIDCapture] = useState<boolean>(false);

  // Other hooks
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const componentName = "Capture";


  const canW = 0.7;

  useEffect(() => {
    if (lastCaptureRef.current) {
      lastCaptureRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }, [captures]);

  /**
   * Calculates the countdown duration for image capture.
   *
   * @returns {number} - The countdown duration in seconds.
   */
  const contdownToCapture = () =>
    process.env.REACT_APP_COUNTDOWN_AUTH_VIDEOCAPTURE
      ? Number(process.env.REACT_APP_COUNTDOWN_AUTH_VIDEOCAPTURE)
      : 10;

  /**
   * Represents the rectangle used for capturing images.
   *
   * @typedef {Object} CaptureRectangle
   * @property {number} x - The x-coordinate of the top-left corner of the rectangle.
   * @property {number} y - The y-coordinate of the top-left corner of the rectangle.
   * @property {number} width - The width of the rectangle.
   * @property {number} height - The height of the rectangle.
   *
   * @type {CaptureRectangle | undefined}
   */
  let currentRect: { x: number; y: number; width: number; height: number } | undefined =
    undefined;

  /**
   * Routes to the next step and stops all video-related processes.
   */
  const routeToNextStep = () => {
    stopVideoAndCameraStream();
    loggerService.addLog(`${componentName} - routeToNextStep`, LogLevelType.Info);
    history.push("?step=2");
  };
  /**
   * Routes back to the previous step and stops all video-related processes.
   */
  const routeBack = () => {
    stopVideoAndCameraStream();
    loggerService.addLog(`${componentName} - routeBack`, LogLevelType.Info);
    history.push("?");
  };

  /**
   * Function to get dimensions for document photo based on type
   * @param {DocumentType} type - The type of document.
   * @returns {Object} - Dimensions of the document photo.
   */
  const getDocumentPhotoRectByType = (type: DocumentType) => {
    switch (type) {
      case DocumentType.ResidencePermit:
        return residencePermitPhotoDimensions;
      case DocumentType.Passport:
        return passPhotoDimensions;
      case DocumentType.IdentityCard:
        return cardIdPhotoDimensions;
      default:
        throw new Error("Unknow photo type");
    }
  };

  // Focuse at the top of the page, if side is loaded
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Request camera stream on component load
  useEffect(() => {
    if (videoRef.current !== null) {
      requestCameraStream();
    }
    return () => {
      stopCameraStream();
    };
  }, [videoRef.current, isMobile]);

  // Attach camera stream to video element
  useEffect(() => {
    const video = videoRef.current;

    if (video === null) {
      return;
    }

    video.srcObject = cameraStream;

    if (cameraStream) {
      video.play();
    }
    return () => {
      stopVideo();
    };
  }, [cameraStream]);

  // Attach video to canvas
  useEffect(() => {
    const video = videoRef.current;
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext("2d");

    if (cameraStream && video && ctx && canvas && !video.paused && !video.ended) {
      const [start, stop] = drawMaskedVideoFrame(video, canvas, ctx);
      start();
      return stop;
    }
  }, [videoRef, canvasRef, cameraStream, modeCardIDCapture]);

  // ================================================================> Drawing on canvas
  /**
   * Function to draw masked video frame
   * @param {HTMLVideoElement} video - The video element.
   * @param {HTMLCanvasElement} canvas - The canvas element.
   * @param {CanvasRenderingContext2D} ctx - The canvas rendering context.
   * @returns {Array<Function>} - Start and stop functions for animation.
   */
  function drawMaskedVideoFrame(
    video: HTMLVideoElement,
    canvas: HTMLCanvasElement,
    ctx: CanvasRenderingContext2D
  ) {
    let requestId: number | undefined;

    /**
     * Function to animate the video frame.
     * Draws the video frame on the canvas with the mask.
     * @returns {void}
     */
    const animate = () => {
      if (video.videoWidth * video.videoHeight > 0) {
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        canvas.width = Math.round(canW * screen.width);
        canvas.height = Math.round(canvas.width / 9 * 16);

        if (modeCardIDCapture) {
          drawWithDocumentMask(ctx, video, canvas);
        } else {
          drawWithParticipantMask(ctx, video, canvas);
        }
      }
      start();
    };

    /**
     * Function to start the animation.
     * @returns {void}
     */
    const start = () => {
      requestId = requestAnimationFrame(animate);
    };

    /**
     * Function to stop the animation.
     * @returns {void}
     */
    const stop = () => {
      if (requestId) {
        cancelAnimationFrame(requestId);
        requestId = undefined;
      }
    };

    return [start, stop];
  }

  // STEP 1 <==========================================> Capture the participant: STEP 1
  /**
   * Function to draw with participant mask
   * @param {CanvasRenderingContext2D} ctx - The canvas rendering context.
   * @param {HTMLVideoElement} video - The video element.
   * @param {HTMLCanvasElement} canvas - The canvas element.
   */
  function drawWithParticipantMask(
    ctx: CanvasRenderingContext2D,
    video: HTMLVideoElement,
    canvas: HTMLCanvasElement
  ) {
    // Blurred Background
    ctx.filter = "blur(1.25rem) opacity(35%)";
    // mirrored for draw of video
    ctx.setTransform(-1.0, 0, 0, 1, canvas.width, 0);
    ctx.drawImage(video, 0, 0);
    ctx.beginPath();

    let canvasWidth = 800;
    let canvasHeight = 450;

    if (isMobile) {
      canvasWidth = Math.round(canW * screen.width);
      canvasHeight = Math.round((16 * canvasWidth) / 9);

      const faceRectHeight = Math.round(canvasHeight * 0.5);
      const faceRectWidth = Math.round(faceRectHeight / 5 * 3);

      const faceRectLeft = Math.round(0.5 * (canvasWidth - faceRectWidth));
      const faceRectTop = Math.round(0.05 * canvasHeight);

      ctx.ellipse(
        faceRectLeft + faceRectWidth * 0.5,
        faceRectTop + faceRectHeight * 0.5,
        faceRectWidth * 0.5,
        faceRectHeight * 0.5,
        0,
        0,
        2 * Math.PI
      );
      ctx.rect(faceRectLeft, faceRectTop, faceRectWidth, faceRectHeight);

      const documentRectHeigth = Math.round(canvasHeight * 0.3);
      const documentRectWidth = Math.round(canvasWidth * 0.7);

      const documentRectLeft = 0.5 * (canvasWidth - documentRectWidth);
      const documentRectTop = 0.65 * canvasHeight;

      ctx.rect(documentRectLeft, documentRectTop, documentRectWidth, documentRectHeigth);
    } else {
      const faceRectWidth = 240;
      const faceRectHeight = 280;
      const faceRectLeft = 0.5 * (canvasWidth - faceRectWidth);
      const faceRectTop = 0.1 * (canvasHeight - faceRectHeight);

      ctx.ellipse(
        faceRectLeft + faceRectWidth * 0.5,
        faceRectTop + faceRectHeight * 0.5,
        faceRectWidth * 0.5,
        faceRectHeight * 0.5,
        0,
        0,
        2 * Math.PI
      );
      ctx.rect(faceRectLeft, faceRectTop, faceRectWidth, faceRectHeight);

      const documentRectWidth = 160;
      const documentRectHeigth = 100;

      const documentRectLeft = 100;
      const documentRectTop = 0.2 * (canvasHeight - documentRectHeigth);

      ctx.rect(documentRectLeft, documentRectTop, documentRectWidth, documentRectHeigth);
    }

    ctx.rect(0, 0, canvasWidth, canvasHeight);

    ctx.clip();

    // CLEAR Image
    ctx.filter = "none";

    // mirrored for draw of video
    ctx.setTransform(-1.0, 0, 0, 1, canvasWidth, 0);
    ctx.drawImage(video, 0, 0, canvasWidth, canvasHeight);
    ctx.strokeStyle = process.env.REACT_APP_PRIMARY_COLOR ?? "#6db4bb";
    ctx.stroke();
  }

  /**
   * Function to get face mask dimensions for step 1
   * @param {number} canvasWidth - The width of the canvas.
   * @param {number} canvasHeight - The height of the canvas.
   * @returns {Object} - Dimensions of the face mask.
   */
  function getFaceMaskDimensions(canvasWidth: number, canvasHeight: number) {
    const xCenter = canvasWidth / 2;

    const ratio = 6 / 7;
    const hFace = isXsMobile ? canvasHeight / 2.2 : canvasHeight / 1.8;

    const wFace = hFace * ratio;
    return { xCenter, hFace, wFace };
  }

  /**
   * Function to get mask document rectangle
   * @param {number} xFace - The x-coordinate of the face.
   * @param {number} yFace - The y-coordinate of the face.
   * @param {number} wFace - The width of the face.
   * @returns {Object} - Rectangle for masking the document.
   */
  function getMaskDocumentRect(canvasWidth: number, canvasHeight: number) {
    const cardDimensions = {
      x: 85.6,
      y: 53.98,
    };

    const xCenter = canvasWidth / 2; // Horizontal center of the canvas
    const pRatio = cardDimensions.x / cardDimensions.y; // Aspect ratio of the card

    // Calculate the width of the rectangle
    const pWidth = isXsMobile
      ? canvasWidth * 0.46
      : isMobile
        ? canvasWidth * 0.6
        : canvasWidth * 0.28;
    // Calculate the height of the rectangle based on the aspect ratio
    const pHeight = pWidth / pRatio;

    // Set the bottom of the rectangle 20px above the canvas bottom
    const bottom = canvasHeight - 20;

    // Calculate the top of the rectangle based on its height
    const top = bottom - pHeight;

    // Center the rectangle horizontally around the canvas center
    const left = xCenter - pWidth / 2;
    const right = xCenter + pWidth / 2;

    // Ensure the rectangle stays at least 1.25rem (20px) from the top edge
    const overflow = top < 20 ? 20 - top : 0;

    return { left, right, bottom: bottom + overflow, top: top + overflow };
  }

  // STEP 2 <=============================================> Capture the document: STEP 2
  /**
   * Function to draw with document mask of the fullsize document capture.
   * @param {CanvasRenderingContext2D} ctx - The canvas rendering context.
   * @param {HTMLVideoElement} video - The video element.
   * @param {HTMLCanvasElement} canvas - The canvas element.
   */
  function drawWithDocumentMask(
    ctx: CanvasRenderingContext2D,
    video: HTMLVideoElement,
    canvas: HTMLCanvasElement
  ) {
    // Blurred Background
    ctx.filter = "blur(1.25rem) opacity(35%)";
    // mirrored for draw of video
    ctx.setTransform(-1.0, 0, 0, 1, canvas.width, 0);

    ctx.drawImage(video, 0, 0);
    ctx.beginPath();

    let canvasWidth = 800;
    let canvasHeight = 450;

    if (isMobile) {
      canvasWidth = Math.round(canW * screen.width);
      canvasHeight = Math.round(16 * canvasWidth / 9);

      const faceRectWidth = Math.round(0.6 * canvasWidth);
      const faceRectHeight = Math.round(faceRectWidth / 6 * 7);
      const faceRectLeft = Math.round(0.5 * (canvasWidth - faceRectWidth));
      const faceRectTop = Math.round(0.075 * canvasHeight);

      ctx.rect(faceRectLeft, faceRectTop, faceRectWidth, faceRectHeight);

      const documentRectWidth = canvasWidth * 0.9;
      const documentRectHeigth = canvasHeight * 0.9;

      const documentRectLeft = Math.round(0.5 * (canvasWidth - documentRectWidth));
      const documentRectTop = Math.round(0.05 * canvasHeight);

      ctx.rect(documentRectLeft, documentRectTop, documentRectWidth, documentRectHeigth);
    } else {
      const faceRectWidth = 230;
      const faceRectHeight = 300;
      const faceRectLeft = 0.2 * (canvasWidth - faceRectWidth);
      const faceRectTop = 0.5 * (canvasHeight - faceRectHeight);

      ctx.rect(faceRectLeft, faceRectTop, faceRectWidth, faceRectHeight);

      const documentRectWidth = 600;
      const documentRectHeigth = 380;

      const documentRectLeft = 0.5 * (canvasWidth - documentRectWidth);
      const documentRectTop = 0.5 * (canvasHeight - documentRectHeigth);

      ctx.rect(documentRectLeft, documentRectTop, documentRectWidth, documentRectHeigth);
    }

    ctx.rect(0, 0, canvasWidth, canvasHeight);

    ctx.clip();

    //CLEAR Image
    ctx.filter = "none";

    // mirrored for draw of video
    ctx.setTransform(-1.0, 0, 0, 1, canvas.width, 0);
    ctx.drawImage(video, 0, 0, canvasWidth, canvasHeight);
    ctx.strokeStyle = process.env.REACT_PRIMARY_COLOR ?? "#6db4bb";
    ctx.lineWidth = 3;
    ctx.stroke();
  }

  /**
   * Calculates the mask rectangle for a full-size document capture in a vertical orientation.
   *
   * @param {number} canvasWidth - The width of the canvas.
   * @param {number} canvasHeight - The height of the canvas.
   * @param {number} padding - The padding around the document.
   * @returns {Object} - The mask rectangle for the full-size document capture.
   */
  function getMaskRectForDocumentFullSize(
    canvasWidth: number,
    canvasHeight: number,
    padding: number
  ): {
    x: number;
    y: number;
    w: number;
    h: number;
    scaleX: number;
    scaleY: number;
  } {
    const ratio = cardIdDimensions.x / cardIdDimensions.y;

    let rectWidth: number;
    let rectHeight: number;

    if (canvasWidth / canvasHeight > ratio) {
      rectHeight = canvasHeight - 2 * padding;
      rectWidth = rectHeight * ratio;
    } else {
      rectWidth = canvasWidth - 2 * padding;
      rectHeight = rectWidth / ratio;
    }

    const x = (canvasWidth - rectWidth) / 2 + padding;
    const y = (canvasHeight - rectHeight) / 2 + padding;

    return {
      x: x,
      y: y,
      w: rectWidth,
      h: rectHeight,
      scaleX: rectWidth / cardIdDimensions.x,
      scaleY: rectHeight / cardIdDimensions.y,
    };
  }

  /**
   * Function to get mask rectangle for photo of document
   * The photo inside the fullsize document capture
   * @param {Object} outer - Outer dimensions.
   * @param {Object} target - Target dimensions.
   * @returns {Object} - Mask rectangle for document photo.
   */
  function getMaskRectForPhotoOfDocument(
    outer: {
      x: number;
      y: number;
      w: number;
      h: number;
      scaleX: number;
      scaleY: number;
    },
    target: {
      x: number;
      y: number;
      w: number;
      h: number;
    }
  ): { x: number; y: number; w: number; h: number } {
    const scaleX = outer.scaleX;
    const scaleY = outer.scaleX;

    // Scale the width and height of the target photo
    const rectWidth = target.w * scaleX;
    const rectHeight = target.h * scaleY;
    // Center the photo vertically in the outer rectangle
    const x = outer.x + target.x;
    const y = outer.y + (outer.h - rectHeight) / 2;

    return { x: x, y: y, w: rectWidth, h: rectHeight };
  }

  // ==========================================================> Camera stream and video
  /**
   * Stops the video playback.
   */
  function stopVideo() {
    const video = videoRef.current;
    if (!video) {
      return;
    }

    video.pause();
    video.srcObject = null;
  }

  /**
   * Stops the camera stream.
   */
  function stopCameraStream() {
    for (const track of cameraStream?.getTracks() ?? []) {
      track.stop();
    }

    setCameraStream(null);
  }

  /**
   * Stops all video-related processes.
   */
  function stopVideoAndCameraStream() {
    try {
      stopVideo();
      stopCameraStream();
    } catch {
      loggerService.addLog(
        `${componentName} - stopVideoAndCameraStream: Camera turned off with error`,
        LogLevelType.Error
      );
    }
  }

  /**
   * Requests access to the camera stream.
   */
  function requestCameraStream() {
    if (cameraStream) {
      return;
    }

    const containerWidth =
      canvasRef.current?.parentElement?.getBoundingClientRect().width ?? 0;
    if (containerWidth === 0) {
      return;
    }

    // the height of the canvas
    const videoHeight = isMobile ? window.innerHeight * 0.5 : window.innerHeight * 0.65;

    navigator.mediaDevices
      .getUserMedia({
        audio: false,
        video: {
          facingMode: "user",
          width: containerWidth - 20,
          height: videoHeight,
        },
      })
      .then(
        (stream) => {
          setCameraStream(stream);
        },
        () =>
          enqueueSnackbar(t("events.authenticationCameraError", { ns: "snackbars" }), {
            variant: "error",
          })
      );
  }

  /**
   * Handles proceeding to the next step based on capture status.
   */
  function handleProceed() {
    if (captures.length === 1 && documentCaptures.length === 1) {
      routeToNextStep();
      return;
    }

    if (documentCaptures.length === 0 && captures.length === 1) {
      setModeCardIDCapture(true);
      return;
    }

    routeBack();
  }

  /**
   * Handles image capture from the canvas.
   */
  const handleCapture = () => {
    const capture = canvasRef.current?.toDataURL();

    if (capture) {
      if (modeCardIDCapture) {
        dispatch(addDocumentCapture(capture));
      } else {
        dispatch(addParticipantCapture(capture));
      }
    }

    setTimerStarted(false);
  };

  /**
   * Determines whether the user can proceed to the next step.
   */
  const canProcced =
    (captures.length === 1 && modeCardIDCapture === false) ||
    (documentCaptures.length === 1 && modeCardIDCapture);

  return (
    <Grid container spacing={2}>
      {/* Capture hints  */}
      <Grid item xs={12}>
        <CaptureHints modeCardIDCapture={modeCardIDCapture} />
      </Grid>

      {/* Canvas and camera */}
      <Grid item xs={12}>
        {isMobile ? (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography>
              {" "}
              {t("shotCommands.example", {
                ns: "authCapture",
              })}
            </Typography>
            <Box display="flex" justifyContent="center">
              <img
                src={modeCardIDCapture ? example2Mobile : example1Mobile}
                alt="Beispiel"
                style={{ maxWidth: "50%" }}
              />
            </Box>
          </Grid>
        ) : null}
        <Box display="flex" justifyContent="center" margin="20">
          <canvas ref={canvasRef} />
        </Box>
        <Grid
          xs={12}
          container
          spacing={2}
          style={{
            justifyContent: "space-between",
          }}
        >
          <Grid item xs={12} sm={6} md={4}>
            {/* The Button to turn on the camera */}
            <Button
              id="camera-on"
              variant="contained"
              fullWidth
              onClick={
                cameraStream === null ? requestCameraStream : stopVideoAndCameraStream
              }
              startIcon={<PlayArrow />}
              color={cameraStream === null ? "primary" : "secondary"}
              disabled={timerStarted}
              style={{ marginBottom: "0.2rem" }}
            >
              {cameraStream === null
                ? t("shotCommands.cameraOn", {
                    ns: "authCapture",
                  })
                : t("shotCommands.cameraOff", {
                    ns: "authCapture",
                  })}
            </Button>
          </Grid>
          {/* The Button to start the shot */}
          <Grid item xs={12} sm={6} md={4}>
            {cameraStream !== null && !timerStarted && (
              <Button
                fullWidth
                id="start-shot"
                onClick={() => {
                  if (isMobile) {
                    handleCapture();
                  } else {
                    setTimerStarted(true);
                  }
                }}
                startIcon={<Camera />}
                variant="contained"
                color="primary"
              >
                {t("shotCommands.startShot", {
                  ns: "authCapture",
                })}
              </Button>
            )}
            {timerStarted ? (
              <CountDown
                seconds={contdownToCapture()}
                onZero={handleCapture}
                color="error"
                variant="h2"
                align="center"
              />
            ) : null}
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} md={2}>
        {isMobile ? null : (
          <Grid item xs={6} md={12}>
            <Typography>
              {" "}
              {t("shotCommands.example", {
                ns: "authCapture",
              })}
            </Typography>
            <img
              src={modeCardIDCapture ? example2 : example1}
              alt="Beispiel"
              style={{ maxWidth: "50%", position: "relative" }}
            />
          </Grid>
        )}
        <Grid item xs={6} md={12}>
          {cameraStream && !isMobile
            ? timerStarted || (
                <span>
                  {t("shotCommands.countdown", {
                    countdown: contdownToCapture(),
                    ns: "authCapture",
                  })}
                </span>
              )
            : null}
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <CaptureList
          isEditable={modeCardIDCapture === false}
          lastCaptureRef={lastCaptureRef}
        />
      </Grid>
      {modeCardIDCapture ? (
        <Grid item xs={12}>
          <CaptureIdCardList />
        </Grid>
      ) : null}
      {/* eslint-disable react/jsx-no-bind */}
      <NavControls canProceed={canProcced} onProceed={handleProceed} />
      <OnSiteAuthPopup />
    </Grid>
  );
};

/**
 * Function to display AusweisIDent alternative information
 */
// TODO add AusweisIDent
// function displayAusweisIDentInformation() {
//   enqueueSnackbar(
//     "“Eine Authentifizierung ist offenbar aktuell nicht möglich. Sie können sich alternativ mit dem AusweisIDent Verfahren authentifizieren”",
//     {
//       variant: "error",
//     }
//   );
// }
