import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  Typography,
  Box,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import DOMPurify from "dompurify";

interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  content: string;
}

const ConfirmationDialog: React.FC<Props> = ({ open, onClose, onConfirm, content }) => {
  const { t } = useTranslation(["common", "snackbars"]);

  const createSafeHTML = (htmlString: string) => {
    return { __html: DOMPurify.sanitize(htmlString) };
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <Box style={{ overflowWrap: "anywhere" }}>
          <Typography dangerouslySetInnerHTML={createSafeHTML(content)} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t("forms.buttons.cancel", { ns: "common" })}
        </Button>
        <Button onClick={onConfirm} color="secondary">
          {t("forms.buttons.confirm", { ns: "common" })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
