import React, { useEffect } from "react";
import { Box, Badge, Tooltip } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { AppState } from "../../../../redux";
import routes from "../../../../routing/routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import { defaultPalette } from "../../../theming/defaultTheme";
import { useTranslation } from "react-i18next";
import { getCart } from "../../../../redux/cart/actions";
import { useDispatch } from "react-redux";

export const Cart: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation(["events", "snackbars"]);

  const cart = useSelector((state: AppState) => state.cart);
  const accessibility = useSelector((state: AppState) => state.accessibility);

  const cartItemsCount = cart.cart?.cart_items?.length || 0;

  useEffect(() => {
    dispatch(getCart());
  }, [cartItemsCount, cart.isLoading]);

  if (cart.isLoading) {
    return null;
  }

  {
    t("dialogues.alertRedirect.countdown", {
      cartItemsCount: cartItemsCount,
      ns: "events",
    });
  }

  const cartItemsCountTranslation = t("cart.items", {
    cartItemsCount: cartItemsCount,
    ns: "events",
  });

  const cartIsEmptyTranslation = t("cart.isEmpty", { ns: "events" });

  return (
    <Box mb={1} ml={2}>
      {cartItemsCount === 0 ? (
        <Tooltip
          color="primary"
          title={cart.isLoading ? "0" : cartIsEmptyTranslation}
          arrow
        >
          <FontAwesomeIcon
            icon={faCartShopping}
            size="2x"
            color={accessibility.monoMode ? "#000" : "#efefef"}
            style={{ marginRight: "5px" }}
            onClick={() => {
              if (cartItemsCount > 0) {
                history.push(routes.checkout);
              }
            }}
          />
        </Tooltip>
      ) : (
        <Badge
          badgeContent={cartItemsCount}
          color="primary"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          overlap="circular"
        >
          <Tooltip color="primary" title={cartItemsCountTranslation} arrow>
            <FontAwesomeIcon
              icon={faCartShopping}
              size="2x"
              color={accessibility.monoMode ? "#000" : defaultPalette.primary?.main}
              style={{ marginRight: "5px", cursor: "pointer" }}
              onClick={() => {
                history.push(routes.checkout);
              }}
            />
          </Tooltip>
        </Badge>
      )}
    </Box>
  );
};
