import React, { useEffect, useState } from "react";
import DOMPurify from "dompurify";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useSelector } from "react-redux";
import { faPhoneFlip, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Icon, Paper, Popper, PopperPlacementType, Slide } from "@material-ui/core";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

import { metaDataService } from "../../../api";
import { AppState } from "../../../redux";
import "./SupportButton.scss";

/**
 * SupportButton component
 *
 * This component is responsible for rendering the support button and the support hotline popper.
 * return: JSX.Element | null
 */
const SupportButton: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [supportHotlineIsEnabled, setSupportHotlineIsEnabled] = useState(false);
  const [german, setGerman] = useState("");
  const [english, setEnglish] = useState("");
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState<PopperPlacementType>();
  const [metaDataIsLoading, setMetaDataIsLoading] = useState(true);
  const accessibility = useSelector((state: AppState) => state.accessibility);

  const { i18n } = useTranslation();

  /**
   * Fetch metadata from the API and set the support hotline content.
   */
  useEffect(() => {
    metaDataService
      .getMetaData()
      .then((metaData) => {
        if (metaData?.data?.support_hotline_is_enabled === true) {
          setSupportHotlineIsEnabled(true);
          setGerman(metaData?.data?.support_hotline_german);
          setEnglish(metaData?.data?.support_hotline_english);
        }
        setMetaDataIsLoading(false);
      })
      .catch(() => {
        setSupportHotlineIsEnabled(false);
        setMetaDataIsLoading(false);
      });
  }, []);

  /**
   * Handle the click event on the support button.
   * @param newPlacement: PopperPlacementType
   * @param event: React.MouseEvent<HTMLButtonElement>
   * return: void
   */
  const handleClick =
    (newPlacement: PopperPlacementType) =>
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
      setOpen((prev) => placement !== newPlacement || !prev);
      setPlacement(newPlacement);
    };

  /**
   * Support hotline text
   */
  const supportText = i18n.language === "de" ? german : english;
  const createSafeHTML = (htmlString: string) => {
    return { __html: DOMPurify.sanitize(htmlString) };
  };

  /**
   * Support button content
   *
   * If the metadata is still loading, display a loading spinner.
   * Otherwise, display the support hotline content.
   */
  const supportContent = metaDataIsLoading ? (
    <Box display="flex" justifyContent="center" alignItems="center" height="100px">
      <CircularProgress />
    </Box>
  ) : (
    <Box className="support-content" style={{ overflowWrap: "anywhere" }}>
      {/* eslint-disable-next-line react/no-danger */}
      <p dangerouslySetInnerHTML={createSafeHTML(supportText)} />
    </Box>
  );

  /**
   * Render the support button and the support hotline popper.
   * If the support hotline is not enabled, return null.
   */
  return supportHotlineIsEnabled && (german || english) ? (
    <>
      <Box
        className="support-button"
        style={{
          backgroundColor: accessibility.monoMode
            ? "#000"
            : process.env.REACT_APP_PRIMARY_COLOR || "#157676",
        }}
        onClick={handleClick("bottom")}
      >
        {/* Icons vertically aligned */}
        <Icon className="support-icon">
          <FontAwesomeIcon icon={faPhoneFlip} />
        </Icon>
        <Icon className="support-icon">
          <FontAwesomeIcon icon={faEnvelope} />
        </Icon>
      </Box>

      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="bottom-end"
        style={{ zIndex: 1300 }}
        transition
      >
        {({ TransitionProps }) => (
          <Slide direction="left" {...TransitionProps} timeout={350}>
            <Paper elevation={3} className="support-popper">
              <Box
                onClick={handleClick("bottom")}
                style={{
                  position: "absolute",
                  zIndex: 3000,
                  top: "1em",
                  right: "1em",
                  cursor: "pointer",
                }}
              >
                <CloseRoundedIcon />
              </Box>

              {supportContent}
            </Paper>
          </Slide>
        )}
      </Popper>
    </>
  ) : null;
};

export default SupportButton;
